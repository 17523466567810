<template>
    <div style="width:100%">
        <div class="of-form-row">
            <el-form-item label="Instrument" prop="isin">
                <el-autocomplete
                    v-model="formdata.isin"
                    :fetch-suggestions="isin_search_async"
                    @select="change_isin"
                    popper-class="of-search-isin">
                        <template slot-scope="{ item }">
                            <div class="flex">
                                <div class="of-search-isin-isin">{{ item.isin }}</div>
                                <div class="of-search-isin-price">
                                    {{ item.cur }}
                                </div>
                            </div>
                            <div  class="of-search-isin-decsription">
                                <div>{{ item.ric }} {{ item.company_name }}</div>
                            </div>
                      </template>
                    </el-autocomplete>
            </el-form-item>

            <el-form-item label="Trade Currency" prop="tcur">
                <el-select 
                    v-model="formdata.tcur"
                    disabled>
                    <el-option
                        v-for="item in currency_list"
                        :key="item.code"
                        :label="item.code"
                        :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="Price" prop="tpr">
                <el-input
                    v-model="formdata.tpr"
                    @blur="blur_format_field('tpr')"
                    :disabled="!this.isin_data.isin">
                    <template slot="prepend">{{formdata.tcur}}</template> 
                </el-input>
            </el-form-item>

            <el-form-item label="Quantity" prop="q">
                <el-input
                    v-model="formdata.q"
                    @blur="blur_format_field('q')"
                    :disabled="!this.isin_data.isin" />
            </el-form-item>

            <el-form-item label="Gross Amount" prop="gross_amount">
                <el-input
                    v-model="formdata.gross_amount"
                    @blur="blur_format_field('gross_amount')"
                    @change="change_gross_amount"
                    :disabled="!this.isin_data.isin">
                    <template slot="prepend">{{formdata.tcur}}</template> 
                </el-input>
            </el-form-item>
        </div>

        <div class="of-form-row" v-if="show_conversion_rate">
            <el-form-item label="Portfolio Conversion Rate" prop="tcur_prate">
                <el-input
                    v-model="formdata.tcur_prate"
                    @blur="blur_format_field('tcur_prate')">
                    <template slot="prepend">{{formdata.tcur}}{{portfolio_cur}}</template>    
                </el-input>
            </el-form-item>
            <el-form-item label="Portfolio Trade Amount" prop="tcur_prate_amount">
                <el-input
                    v-model="formdata.tcur_prate_amount"
                    @blur="blur_format_field('tcur_prate_amount')"
                    @change="change_tcur_prate_amount">
                    <template slot="prepend">{{portfolio_cur}}</template>    
                </el-input>
            </el-form-item>
        </div>
    </div>
</template>

<script>
import common from './operation_mixin'
import { num_to_money } from '@/utils'

export default {
    mixins: [common],

    data(){
        return {
            asset: 'equity',
        }
    },

    methods: {
        change_isin(item){
            this.clear_data()

            this.$set(this.formdata, 'isin', item.isin)
            this.isin_data = { isin : this.formdata.isin }
            this.set_isin_data(item)

            this.$set(this.formdata, 'tcur', item.cur)
            this.$set(this.formdata, 'st_cur', item.cur)
            this.$set(this.formdata, 'st_cur_rate', 1)

            this.get_isin_price_data()
        },

        load_isin(isin){
            this.$set(this.formdata, 'isin', isin);
            this.$store.dispatch('instrument/getInstrumentShort', { asset: this.asset, isin: isin, dt:this.trade_date }).then((response) => {
                this.change_isin(response)
            })
        },

        get_isin_price_data(){
            this.get_equity_isin_price_data()
        },

        change_gross_amount(){
            let gross_amount = this.get_number_field('gross_amount')
            let q = this.get_number_field('q')
            if (q) {
                this.$set(this.formdata, 'tpr', gross_amount / q)
            }
            else {
                this.$set(this.formdata, 'tpr', 0);
            }
        },

        count_amounts(){
            let q = this.get_number_field('q')
            let tpr = this.get_number_field('tpr')
            let tcur_prate = this.get_number_field('tcur_prate')
            
            this.$set(this.formdata, 'gross_amount', q * tpr)
            this.$set(this.formdata, 'tcur_prate_amount', q * tpr * tcur_prate)
        },
    },

    watch: {
        trade_date(val){
            if (this.formdata.isin) this.get_isin_price_data();
        },

        "formdata.tpr": function(val, old_val) {
            if (old_val === val) return;

            this.count_amounts()
        },

        "formdata.q": function(val, old_val) {
            if (old_val === val) return;

            this.count_amounts()
        },

        "formdata.tcur_prate": function(val, old_val) {
            if (old_val === val) return;
            
            let q = this.get_number_field('q')
            let tpr = this.get_number_field('tpr')
            let tcur_prate = this.get_number_field('tcur_prate')
            this.$set(this.formdata, 'tcur_prate_amount', q * tpr * tcur_prate)
        }
    }
}
</script>
