<template>
    <div  class="prtf-page" v-if="client && (operation || portfolioid)">
        <div class="prtf-page-header">
            <div class="prtf-page-header-title-holder">
                <div class="prtf-page-header-maintitle">
                    <div class="prtf-page-header-name" v-if="operation"> Edit Operation</div>
                    <div class="prtf-page-header-name" v-else> New Operation</div>
                    <div class="prtf-page-header-subname" v-if="operation && selected_portfolio.currency">
                        <TransactionText 
                            :operation="operation" 
                            :container_cur="selected_portfolio.currency" />
                    </div>
                </div>
                <div class="prtf-page-header-subtitle">
                    <ClientHeaderLink :client="client" />
                </div>
            </div>
        </div>

        <div class="prtf-page-body  prtf-page-body-flex">
            <el-form
                label-position="top"
                :model="formdata"
                ref="OperationEditor"
                autocomplete="off"
                :validate-on-rule-change="false"
                class="operation-form"
                :rules="validation_rules"
                v-if="client && (operation || portfolioid)">
                
                <div class="operation-form-body">

                    <div class="of-form-row">
                        <el-form-item label="Portfolio" prop="portfolio_id" class="of-col-3">
                            <el-select 
                                v-model="formdata.portfolio_id" 
                                class="of-portfolio-select"
                                @change="change_portfolio()" 
                                :disabled="!!formdata._id || (type && ['collateral_block','collateral_unblock','dividend','coupon'].includes(type))">
                                <div slot="prefix">{{selected_portfolio.currency}}</div>
                                <el-option
                                    v-for="item in portfolio_list"
                                    :key="item._id"
                                    :label="item.name"
                                    :value="item._id">
                                    <b>{{item.currency}}</b> <span>{{item.name}}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item></el-form-item>
                    </div>

                    <div class="of-form-row">
                        <el-form-item label="Operation Asset" prop="asset">
                            <el-select v-model="formdata.asset" @change="change_asset" :disabled="type && ['collateral_block','collateral_unblock','dividend','coupon'].includes(type)">
                                <el-option 
                                    v-for = "option in asset_types"
                                    :key="option.value"
                                    :label="option.label" 
                                    :value="option.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Operation Type" prop="t">
                            <el-select v-model="formdata.t" @change="change_operation_type" :disabled="type && ['collateral_block','collateral_unblock','dividend','coupon'].includes(type)">
                                <el-option 
                                    v-for = "option in operation_types"
                                    :key="option.value"
                                    :label="option.label" 
                                    :value="option.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Trade Date" prop="tdt">
                            <el-date-picker
                                v-model="formdata.tdt"
                                type="datetime"
                                :clearable="false"
                                format="dd MMM yyyy HH:mm:ss"
                                :picker-options="{disabledDate: disabled_trade_date}"
                                @change="change_trade_date()">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="Value Date" prop="vdt">
                            <el-date-picker
                                v-model="formdata.vdt"
                                type="date"
                                :clearable="false"
                                format="dd MMM yyyy"
                                :disabled="type && ['collateral_block','collateral_unblock','dividend','coupon'].includes(type)"
                                :picker-options="{disabledDate: disabled_value_date}">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="Debit Account" prop="inacc" v-if="!disabled_inacc">
                            <el-select v-model="formdata.inacc" :disabled="disabled_inacc">
                                <el-option 
                                    v-for = "option in accounts"
                                    :key="option._id"
                                    :label="option.name" 
                                    :value="option._id"
                                    :disabled="option.disabled">
                                    <span>{{option.name}}</span> <span v-if="option.type">({{option.type}})</span>
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Credit Account" prop="outacc" v-if="!disabled_outacc">
                            <el-select v-model="formdata.outacc" :disabled="disabled_outacc">
                                <el-option 
                                    v-for = "option in accounts"
                                    :key="option._id"
                                    :label="option.name" 
                                    :value="option._id"
                                    :disabled="option.disabled">
                                    <span>{{option.name}}</span> <span v-if="option.type">({{option.type}})</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>

                    <div class="of-form-row">
                        <el-form-item class="of-instrument-info" v-if="isin_data.isin" v-bind:class="[formdata._id ? 'of-col-3' : 'of-col-2']">
                            <div class="ag-ecl-title">
                                {{isin_data.name}}
                            </div>
                            <template v-if="formdata.asset === 'future'">
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Base Date:</div>
                                    <div class="ag-ecl-row-value">
                                        <FormattedDate :value="isin_data.basedate" />
                                    </div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Expiration Date:</div>
                                    <div class="ag-ecl-row-value">
                                        <FormattedDate :value="isin_data.expdate" />
                                    </div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Tick size:</div>
                                    <div class="ag-ecl-row-value">{{isin_data.ticks}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Tick value:</div>
                                    <div class="ag-ecl-row-value">{{isin_data.tickv}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Underlying instrument:</div>
                                    <div class="ag-ecl-row-value">{{isin_data.uname}}</div>
                                </div>
                            </template>
                            <template v-if="formdata.asset === 'bond'">
                                <div class="ag-ecl-row" v-if="isin_data.company_name">
                                    <div class="ag-ecl-row-title">{{isin_data.company_name}}</div>
                                </div><div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">ISIN:</div>
                                    <div class="ag-ecl-row-value">{{isin_data.isin}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Sinkfactor:</div>
                                    <div class="ag-ecl-row-value">{{isin_data.sinkfactor}}</div>
                                </div>
                                <div class="ag-ecl-row" v-if="isin_data.maturity">
                                    <div class="ag-ecl-row-title">Maturity:</div>
                                    <div class="ag-ecl-row-value"><FormattedDate :value="isin_data.maturity" /></div>
                                </div>
                            </template>
                            <template v-if="formdata.asset === 'equity'">
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">ISIN:</div>
                                    <div class="ag-ecl-row-value">{{isin_data.isin}}</div>
                                </div>
                            </template>
                            <template v-if="formdata.asset === 'option'">
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Base Date:</div>
                                    <div class="ag-ecl-row-value">
                                        <FormattedDate :value="isin_data.basedate" />
                                    </div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Expiration Date:</div>
                                    <div class="ag-ecl-row-value">
                                        <FormattedDate :value="isin_data.expdate" />
                                    </div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Type:</div>
                                    <div class="ag-ecl-row-value">{{isin_data.type}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Strike Price:</div>
                                    <div class="ag-ecl-row-value">{{isin_data.strike}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Lot Size:</div>
                                    <div class="ag-ecl-row-value">{{isin_data.size}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Underlying instrument:</div>
                                    <div class="ag-ecl-row-value">{{isin_data.uname}}</div>
                                </div>
                            </template>
                        </el-form-item>
                        <el-form-item class="of-instrument-info of-col-2" v-if="isin_grid_show && formdata.isin && !formdata._id">
                            <InstrumentBalanceGrid
                                :name="formdata.isin"
                                :data="isin_grid_data" />
                        </el-form-item>
                        <el-form-item class="of-instrument-info of-col-2" v-if="tcur_grid_show && formdata.isin && formdata.tcur && formdata.tcur !== formdata.isin && !formdata._id && !st_cur_grid_show">
                            <InstrumentBalanceGrid
                                :name="formdata.tcur"
                                :data="tcur_grid_data" />
                        </el-form-item>
                        <el-form-item class="of-instrument-info of-col-2" v-if="st_cur_grid_show && formdata.st_cur && formdata.st_cur !== formdata.isin && !formdata._id">
                            <InstrumentBalanceGrid
                                :name="formdata.st_cur"
                                :data="st_cur_grid_data" />
                        </el-form-item>
                    </div>

                    <div class="of-form-row"  v-if="selected_portfolio._id && formdata.asset && formdata.t">
                        <CashDW
                            ref="CashDW"
                            v-if="formdata.asset === 'cash' && ['deposit','withdraw'].includes(formdata.t) && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <CashSB
                            ref="CashSB"
                            v-if="formdata.asset === 'cash' && ['sell','buy','sell_short','cover_short'].includes(formdata.t) && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <FutureSB
                            ref="FutureSB"
                            v-if="formdata.asset === 'future' && ['sell','buy','sell_short','cover_short'].includes(formdata.t) && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <BondDW
                            ref="BondDW"
                            v-if="formdata.asset === 'bond' && ['deposit','withdraw'].includes(formdata.t) && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <BondSB
                            ref="BondSB"
                            v-if="formdata.asset === 'bond' && ['sell','buy','sell_short','cover_short'].includes(formdata.t) && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <EquityDW
                            ref="EquityDW"
                            v-if="formdata.asset === 'equity' && ['deposit','withdraw'].includes(formdata.t) && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <EquitySB
                            ref="EquitySB"
                            v-if="formdata.asset === 'equity' && ['sell','buy','sell_short','cover_short'].includes(formdata.t) && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <OptionDW
                            ref="OptionDW"
                            v-if="formdata.asset === 'option' && ['deposit','withdraw'].includes(formdata.t) && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <OptionSB
                            ref="OptionSB"
                            v-if="formdata.asset === 'option' && ['sell','buy','sell_short','cover_short'].includes(formdata.t) && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <OperBlock
                            ref="OperBlock"
                            v-if="formdata.t && formdata.t === 'collateral_block' && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            :asset="formdata.asset"
                            :client_id="client._id"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <OperUnBlock
                            ref="OperUnBlock"
                            v-if="formdata.t && formdata.t === 'collateral_unblock' && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            :asset="formdata.asset"
                            :account="formdata.inacc"
                            :client_id="client._id"
                            :isin_balance="isin_grid_data"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <OperDividend
                            ref="OperDividend"
                            v-if="formdata.t && formdata.t === 'dividend' && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            :asset="formdata.asset"
                            :account="formdata.inacc"
                            :client_id="client._id"
                            :portfolio_date="selected_portfolio.date"
                            :isin_balance="isin_grid_data"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                        <OperCoupon
                            ref="OperCoupon"
                            v-if="formdata.t && formdata.t === 'coupon' && selected_portfolio.currency"
                            :portfolio_id="selected_portfolio._id"
                            :portfolio_cur="selected_portfolio.currency"
                            :trade_date="formdata.tdt"
                            :operation_type="formdata.t"
                            :asset="formdata.asset"
                            :account="formdata.inacc"
                            :client_id="client._id"
                            :portfolio_date="selected_portfolio.date"
                            :isin_balance="isin_grid_data"
                            @field-change="field_change($event)"
                            @change-isin="change_isin"
                            />
                    </div>

                    <template v-if="!formdata.t || !['collateral_block','collateral_unblock'].includes(formdata.t)">
                        <div class="of-fees">
                            <div class="of-fees-title">Charges</div>
                            <FeeRow 
                                v-if="selected_portfolio.currency"
                                :fee="fee"
                                :accounts="accounts" 
                                :portfolio_cur="selected_portfolio.currency"
                                :trade_date="formdata.tdt"
                                v-for="(fee, index) in formdata.fees" 
                                :key="index"
                                @remove-fee="remove_fee(index)" />
                        </div>

                        <div class="of-fees-btn" @click="add_fee">
                            <i class="el-icon-circle-plus-outline"></i> charges
                        </div>
                    </template>

                    <div class="of-comment-btn" @click="show_comment=true" v-if="!show_comment">
                        <i class="el-icon-circle-plus-outline"></i> comment
                    </div>
                    <el-form-item label="Comment" prop="cmnt" v-else>
                        <el-input v-model="formdata.cmnt" type="textarea" autosize />
                    </el-form-item>

                </div>
                <div class="operation-form-footer">
                    <div class="spacer" />
                    <el-button @click="close_tab">Close Tab</el-button>
                    <el-button type="primary" @click="save_operation">Save operation</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Urls from '@/../config/url.js'
import moment from 'moment-timezone'
var momentBusinessDays = require("moment-business-days")
import { num_to_money, make_format_string, money_to_num, date_to_string, date_to_moment } from '@/utils'
import TransactionText from '@/components/PageComponents/TransactionText'
import FormattedDate from '@/components/Formats/FormattedDate'
import ClientHeaderLink from '@/components/PageComponents/ClientHeaderLink'

import InstrumentBalanceGrid from './InstrumentBalanceGrid'
import FeeRow from './FeeRow'

import BondDW from './OperationBondDW'
import BondSB from './OperationBondSB'
import CashDW from './OperationCashDW'
import CashSB from './OperationCashSB'
import FutureSB from './OperationFutureSB'
import EquityDW from './OperationEquityDW'
import EquitySB from './OperationEquitySB'
import OptionDW from './OperationOptionDW'
import OptionSB from './OperationOptionSB'
import OperBlock from './OperationBlock'
import OperUnBlock from './OperationUnBlock'
import OperDividend from './OperationDividend'
import OperCoupon from './OperationCoupon'

export default {
    name: 'new_operation',
    components: { 
        InstrumentBalanceGrid, 
        FeeRow, 
        TransactionText,
        ClientHeaderLink,
        FormattedDate,
        BondDW,
        BondSB,
        CashDW,
        CashSB,
        FutureSB,
        EquityDW,
        EquitySB,
        OptionDW,
        OptionSB,
        OperBlock,
        OperUnBlock,
        OperDividend,
        OperCoupon,
    },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
        asset: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
        },
        isin: {
            type: String,
            required: false,
        },
        operationid: {
            required: false,
        },
    },

    data(){
        return {
            client             : undefined,
            operation          : undefined,
            formdata           : {},
            selected_portfolio : {},
            accounts           : [],
            portfolio_list     : [],
            strategy_list      : [],
            isin_data          : {},
            isin_grid_data     : [],
            isin_grid_show     : false,
            tcur_grid_data     : [],
            tcur_grid_show     : false,
            st_cur_grid_data   : [],
            st_cur_grid_show   : false,
            show_comment       : false,

            operation_types_init: [
                {value: 'buy', label: 'Buy'},
                {value: 'sell', label: 'Sell'},
                {value: 'cover_short', label: 'Cover Short'},
                {value: 'sell_short', label: 'Sell Short'},
                {value: 'deposit', label: 'Deposit'},
                {value: 'withdraw', label: 'Withdraw'},
                {value: 'collateral_block', label: 'Collateral Block'},
                {value: 'collateral_unblock', label: 'Collateral Unblock'},
                {value: 'dividend', label: 'Dividend'},
                {value: 'coupon', label: 'Coupon'},
            ]
        }
    },

    computed: {
        ...mapState({
            currency_list : state => state.currency.list,
        }),

        asset_types(){
            if (!this.formdata.t || !['deposit','withdraw'].includes(this.formdata.t))
                return [
                    {value: 'equity', label: 'Equity'},
                    {value: 'bond', label: 'Fixed Income'},
                    {value: 'future', label: 'Future'},
                    {value: 'option', label: 'Option'},
                    {value: 'cash', label: 'Cash'},
                ]; 
            else 
                return [
                    {value: 'equity', label: 'Equity'},
                    {value: 'bond', label: 'Fixed Income'},
                    {value: 'option', label: 'Option'},
                    {value: 'cash', label: 'Cash'},
                ]; 
        },
        operation_types(){
            let operation_types = this.operation_types_init;

            if (this.formdata.asset && ['future'].includes(this.formdata.asset))
                operation_types = operation_types.filter(item => !['deposit', 'withdraw', 'dividend','coupon'].includes(item.value))

            if (!this.isin)
                operation_types = operation_types.filter(item => !['collateral_block', 'collateral_unblock', 'dividend','coupon'].includes(item.value))

            if (this.asset && this.asset === 'equity' && this.isin && this.type && this.type === 'dividend')
                operation_types = operation_types.filter(item => item.value === 'dividend')
            else
                operation_types = operation_types.filter(item => item.value !== 'dividend')

            if (this.asset && this.asset === 'bond' && this.isin && this.type && this.type === 'coupon')
                operation_types = operation_types.filter(item => item.value === 'coupon')
            else
                operation_types = operation_types.filter(item => item.value !== 'coupon')

            return operation_types
        },

        value_date_dif(){
            let value_date_dif = 2;

            if (this.formdata.t && ['withdraw', 'deposit', 'collateral_block', 'collateral_unblock', 'dividend','coupon'].includes(this.formdata.t)) value_date_dif = 0;
            if (this.formdata.asset && this.formdata.asset === 'future')  value_date_dif = 0;

            return value_date_dif
        },

        disabled_inacc(){
            if (this.formdata.t && ['withdraw', 'collateral_block'].includes(this.formdata.t))
                return true;
            else if (this.formdata.t && this.formdata.asset && this.formdata.asset === 'future' && ['sell','sell_short'].includes(this.formdata.t))
                return true;
            return false;
        },
        disabled_outacc(){
            if (this.formdata.t && ['deposit', 'collateral_unblock', 'dividend','coupon'].includes(this.formdata.t))
                return true;
            else if (this.formdata.t && this.formdata.asset && this.formdata.asset === 'future' && ['buy','cover_short'].includes(this.formdata.t))
                return true;
            return false;
        },

        validation_rules() {
            let rules = {
                portfolio_id : [{ required: true, message: ' ', trigger: 'blur' }],
                asset        : [{ required: true, message: ' ', trigger: 'blur' }],
                t            : [{ required: true, message: ' ', trigger: 'blur' }],
                tdt          : [{ required: true, message: ' ', trigger: 'blur' }],
                vdt          : [{ required: true, message: ' ', trigger: 'blur' }],
                isin         : [{ required: true, message: ' ', trigger: 'blur' }],
                q            : [{ required: true, message: ' ', trigger: 'blur', validator: this.more_than_zero_validator }],
                tcur         : [{ required: true, message: ' ', trigger: 'blur' }],
                tpr          : [{ required: true, message: ' ', trigger: 'blur', validator: this.more_than_zero_validator }],
                tcur_prate   : [{ required: true, message: ' ', trigger: 'blur', validator: this.more_than_zero_validator }],
                inacc        : [{ required: true, message: ' ', trigger: 'blur', }],
                outacc       : [{ required: true, message: ' ', trigger: 'blur', }],
            }

            if (this.formdata.t && ['dividend','coupon'].includes(this.formdata.t)){
                rules['cutoff_dt'] = [{ required: true, message: ' ', trigger: 'blur', }];
                rules['tpr'] = [{ required: true, message: ' ', trigger: 'blur', validator: this.not_zero_validator }];
            }

            return rules
        }
    },

    methods: {
        change_portfolio(){
            this.clear_data()
            this.get_portfolio_data();
        },
        change_trade_date(){
	    console.log(this.formdata.tdt)
            let vdt = momentBusinessDays(this.formdata.tdt).businessAdd(this.value_date_dif)._d
            this.$set(this.formdata, 'vdt', vdt)
        },
        change_operation_type(){
            let vdt = momentBusinessDays(this.formdata.tdt).businessAdd(this.value_date_dif)._d
            this.$set(this.formdata, 'vdt', vdt)
            this.clear_data()
        },
        change_asset(){
            this.clear_data()
            this.change_trade_date()
        },

        get_portfolio_data(){
            let selected_portfolio = this.portfolio_list.find(portfolio => portfolio._id === this.formdata.portfolio_id)
            if (selected_portfolio) {
                this.selected_portfolio = selected_portfolio;
                return this.$store.dispatch('portfolioAccounts/getList', {
                    portfolio_id : this.selected_portfolio._id,
                }).then((response) => {
                    this.accounts = response.filter(item => !item.inactive)
                    if (this.accounts.length == 1) {
                        this.formdata.inacc = this.accounts[0]['_id'];
                        this.formdata.outacc = this.accounts[0]['_id'];
                    }
                })
            }
            else {
                this.selected_portfolio = {}
                this.accounts = []
                return
            }
        },
        get_portfolio_list(){
            return this.$store.dispatch('portfolio/getList', {
                client_id : this.client._id,
                mode      : 'trade',
                date      : this.formdata.tdt
            }).then((response) => {
                this.portfolio_list = response
            })
        },
        
        change_isin(data){
            this.isin_data = data
            this.get_balances(this.formdata.isin).then((response) => {
                this.isin_grid_show = true;
                this.isin_grid_data = response;
            })
        },
        change_tcur(){
            if (!this.formdata.tcur) return;
            this.get_balances(this.formdata.tcur).then((response) => {
                this.tcur_grid_show = true;
                this.tcur_grid_data = response;
            })
        },
        change_st_cur(){
            if (!this.formdata.st_cur) return;
            this.get_balances(this.formdata.st_cur).then((response) => {
                this.st_cur_grid_show = true;
                this.st_cur_grid_data = response;
                this.tcur_grid_show = false;
            })
        },
        check_accounts_availability(){
            if (this.type && this.type === 'collateral_unblock') {
                let available_accs = []
                this.isin_grid_data.forEach(row => {
                    if (row.blocked_balance && row.blocked_balance > 0)
                        available_accs.push(row.account_id)
                })
                this.accounts.forEach(acc => {
                    if (!available_accs.includes(acc._id))
                        acc.disabled = true;
                    else
                        acc.disabled = false;
                })
            }
        },

        clear_data(){
            this.isin_data        = {};
            this.isin_grid_data   = [];
            this.tcur_grid_data   = [];
            this.st_cur_grid_data = [];
            this.isin_grid_show   = false;
            this.tcur_grid_show   = false;
            this.st_cur_grid_show = false;

            ['CashDW','CashSB','EquityDW','EquitySB','FutureSB','BondDW','BondSB','OptionDW','OptionSB','OperBlock','OperUnBlock','OperDividend','OperCoupon'].forEach(k => {
                if (this.$refs[k]) this.$refs[k].clear_data();
            })
        },

        disabled_trade_date(date){
            let is_disabled = true
            if (date >= this.$moment(this.selected_portfolio.date).toDate() && date <= new Date())
                is_disabled = false
            
            return is_disabled
        },
        disabled_value_date(date){
            let is_disabled = true
            if (date >= this.$moment(this.selected_portfolio.date).toDate())
                is_disabled = false
            
            return is_disabled
        },

        field_change(field_data){
            this.$set(this.formdata, field_data.field, field_data.value)
        },

        get_balances(isin) {
            let url = Urls.accountsIsinBalance 
                + '?client_id=' + this.client._id 
                + '&isin=' + isin 
                + '&mode=' + 'trade' 
                + '&portfolio_id=' + this.portfolioid
                + '&date=' + this.$moment(this.formdata.tdt).format('YYYY-MM-DD')

            return new Promise(resolve => {
                this.axios.get(url)
                    .then(response => {
                        resolve(response.data._items)
                    })
                    .catch(e => {
                        console.log(e)
                        this.$message({
                            message: 'Cannot get Accounts Balance Data!',
                            type: 'error'
                        })
                        resolve([])
                    })
            }) 
        },

        add_fee(){
            let new_fee = {
                t     :'', 
                am    : '', 
                cur   : this.selected_portfolio.currency, 
                prate : 1, 
                srate : 1, 
                acc   : undefined
            }

            if (this.accounts.length == 1)
                new_fee.acc = this.accounts[0]['_id'];

            this.formdata.fees.push(new_fee)
        },
        remove_fee(index) {
            this.formdata.fees.splice(index, 1)
        },

        more_than_zero_validator(rule, value, callback){
            let vl = money_to_num(value)
            if (vl && vl > 0)
                callback()
            else
                callback(new Error('must be bigger than 0'))
        },

        not_zero_validator(rule, value, callback){
            let vl = money_to_num(value)
            if (vl && vl != 0)
                callback()
            else
                callback(new Error('must not be 0'))
        },

        save_operation(){
            let $this = this;
            let Editor = this.$refs.OperationEditor
            Editor.validate((valid) => {

                let send_form = JSON.parse(JSON.stringify(this.formdata));
                if (!valid) return false;

                let fees_valid = true
                let fee_money_fields = ['am','prate','srate']
                send_form.fees.forEach(fee => {
                    fee_money_fields.forEach(field => {
                        fee[field] = money_to_num(fee[field])
                    })
                    if (!fee.acc || !fee.am || !fee.cur || !fee.prate)
                        fees_valid = false;
                    else if (send_form.strategy_id && !fee.srate)
                        fees_valid = false;
                })
                if (!fees_valid) {
                    this.$message({
                        message: 'Please, check that Charges are filled correctly',
                        type: 'error'
                    });
                    return;
                }

                let tdt = $this.$moment(send_form.tdt)
                let vdt = $this.$moment(send_form.vdt)
                vdt.set({
                    hour:   tdt.get('hour'),
                    minute: tdt.get('minute'),
                    second: tdt.get('second')
                });

                send_form.tdt = tdt.format('YYYY-MM-DD HH:mm:ss ZZ')
                send_form.vdt = vdt.format('YYYY-MM-DD HH:mm:ss ZZ')

                if (send_form.cutoff_dt) {
                    var hrs_offset = -(new Date().getTimezoneOffset() / 60)
                    let cutoff_dt = $this.$moment(send_form.cutoff_dt).utcOffset(hrs_offset)
                    send_form.cutoff_dt = cutoff_dt.format('YYYY-MM-DD HH:mm:ss ZZ')
                }
                

                const recalculate_loading = this.$loading({
                    lock: true,
                    text: 'Saving Operation and Recalculating Portfolio Data',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                if (send_form.asset === 'future' || send_form.asset === 'option')
                    send_form.dscode = send_form.isin;

                let excess_fields = [
                    'basedate',
                    'cur',
                    'expdate',
                    'name',
                    'ticks',
                    'tickv',
                    'uname',
                    'company_name',
                    'maturity',
                    'sinkfactor',
                    'ric',
                    'size',
                    'strike',
                    'type',
                ]
                excess_fields.forEach(field => {
                    delete send_form[field];
                })


                if (send_form._id) {
                    $this.$store.dispatch('portfolioOperations/updateItem', send_form).then(() => {
                        $this.close_tab()
                        this.$emit('update-portfolio-data', { clientid: this.client.id, portfolioid: send_form.portfolio_id })
                        recalculate_loading.close();
                    })
                }
                else {
                    $this.$store.dispatch('portfolioOperations/addItem', send_form).then(() => {
                        $this.clear_data()
                        $this.get_clean_form()
                        recalculate_loading.close();
                        this.$emit('update-portfolio-data', { clientid: this.client.id, portfolioid: send_form.portfolio_id })
                    })
                }
            })
        },

        get_clean_form(){
            this.formdata = {
                client_id            : this.client._id,
                portfolio_id         : this.portfolioid,
                asset                : this.asset,
                t                    : this.type,
                //tdt                  : tdt,
                //vdt                  : vdt,
                fees                 : [],
            };

            let tdt = this.$moment().toDate()
            let vdt = momentBusinessDays(tdt).businessAdd(this.value_date_dif)._d

            this.$set(this.formdata, 'tdt', tdt)        
            this.$set(this.formdata, 'vdt', vdt) 
            
            if (this.accounts.length == 1) {
                this.formdata.inacc = this.accounts[0]['_id'];
                this.formdata.outacc = this.accounts[0]['_id'];
            }  
        },

        get_filled_form(){
            this.formdata  = Object.assign({}, this.operation);
            this.formdata.portfolio_id = this.operation.portfolio_id['$oid']
            this.formdata._id          = this.operation._id['$oid']
            this.formdata.client_id    = this.client._id
            if (this.operation.inacc)
                this.formdata.inacc = this.operation.inacc['$oid'];
            if (this.operation.outacc)
                this.formdata.outacc = this.operation.outacc['$oid'];

            var hrs_offset = -(new Date().getTimezoneOffset() / 60)
            this.formdata.tdt = date_to_moment(this.formdata.tdt).utcOffset(hrs_offset).toDate()
            this.formdata.vdt = date_to_moment(this.formdata.vdt).utcOffset(hrs_offset).toDate()
            
            this.formdata.fees.forEach((fee, index) => {
                if (typeof fee.acc === 'object')
                    fee.acc = fee.acc['$oid']
            })
            if (this.formdata.cmnt) this.show_comment = true;

            let $this = this
            $this.get_portfolio_list().then(() => {
                $this.get_portfolio_data();
                $this.$nextTick().then(function () {
                    ['CashDW','CashSB','EquityDW','EquitySB','FutureSB','BondDW','BondSB','OptionDW','OptionSB','OperBlock','OperUnBlock','OperDividend','OperCoupon'].forEach(k => {
                        if ($this.$refs[k]) $this.$refs[k].fill_data($this.formdata);
                    })
                });
            });
        },

        load_isin(){
            if (!this.isin || !this.asset || !this.type) return;
            ['CashDW','CashSB','EquityDW','EquitySB','FutureSB','BondDW','BondSB','OptionDW','OptionSB','OperBlock','OperUnBlock','OperDividend','OperCoupon'].forEach(k => {
                if (this.$refs[k]) this.$refs[k].load_isin(this.isin);
            })
        },

        close_tab(){
            this.$emit('tab-close')
        }
    },

    mounted(){
        this.isin_grid_data   = []
        this.tcur_grid_data   = []
        this.st_cur_grid_data = []
        this.isin_grid_show   = false
        this.tcur_grid_show   = false
        this.st_cur_grid_show = false

        let $this = this

        if (this.clientid && this.portfolioid) {
            this.$store.dispatch('currency/getList');

            this.$store.dispatch('clients/getClient', this.clientid).then((client_response) => {
                this.client = client_response
                if (!this.client) return;

                if (this.operationid) {
                    this.$store.dispatch('portfolioOperations/getItem', { id: this.operationid, client_id:this.client._id }).then((operation_response) => {
                        this.operation = operation_response
                        if (!this.operation) return;
                        this.get_filled_form()
                        this.$emit('tab-title-change', 'Edit Operation ' + this.operation.opvid + ' for ' + this.client.first_name + ' ' + this.client.last_name)
                    })
                }
                else if (this.portfolioid){
                    this.get_clean_form()
                    this.$emit('tab-title-change', 'New Operation for ' + this.client.first_name + ' ' + this.client.last_name,)
                    this.$nextTick().then(function () {
                        $this.get_portfolio_list().then(() => {
                            $this.get_portfolio_data().then(() => {
                                $this.load_isin()
                            })
                        });
                    })
                }
            })
        }
    },

    watch: {
        "formdata.tcur": function(val){
            let $this = this
            this.$nextTick().then(function () {
                $this.change_tcur()
            })
        },
        "formdata.st_cur": function(val){
            let $this = this
            this.$nextTick().then(function () {
                $this.change_st_cur()
            })
        },
        "formdata.vdt": function(val, old_val){
            if(val <= this.formdata.tdt) this.formdata.vdt = old_val
        },
        accounts(){
            this.check_accounts_availability()
        },
        isin_grid_data(){
            this.check_accounts_availability()
        },
    }
}
</script>
