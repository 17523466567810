<template>
    <div>
        <div class="of-form-fees-row of-form-fees-title_row">
            <div class="of-row-fees-col-del"></div>
            <div class="of-row-fees-col">Charge Type</div>
            <div class="of-row-fees-col">Account</div>
            <div class="of-row-fees-col">Ccy</div>
            <div class="of-row-fees-col">Amount</div>
            <div class="of-row-fees-col">
                <div v-if="show_prate">Rate to Portf. Ccy</div>
            </div>
            <div class="of-row-fees-col">
                <div v-if="show_prate">Amount in Portf. Ccy</div>
            </div>
            <!-- <div class="of-row-fees-col">
                <div v-if="show_srate">Rate to Str. Ccy</div>
            </div> -->
        </div>
        <div class="of-form-fees-row">
            <div class="of-row-fees-col-del">
                <el-button type="text" class="form-delete-btn" @click="remove_fee">
                    <i class="el-icon-circle-close"></i>
                </el-button>
            </div>
            <div class="of-row-fees-col">
                <el-input v-model="fee_data.t" disabled />
            </div>
            <div class="of-row-fees-col">
                <el-select v-model="fee_data.acc">
                    <el-option
                        v-for="item in accounts"
                        :key="item._id"
                        :label="item.name"
                        :value="item._id">
                    </el-option>
                </el-select>
            </div>
            <div class="of-row-fees-col">
                <el-select v-model="fee_data.cur">
                    <el-option
                        v-for="item in currency_list"
                        :key="item.code"
                        :label="item.code"
                        :value="item.code">
                    </el-option>
                </el-select>
            </div>
            <div class="of-row-fees-col">
                <el-input  v-model="fee_data.am"/>
            </div>
            <div class="of-row-fees-col">
                <el-input v-model="fee_data.prate" v-if="show_prate">
                    <template slot="prepend">{{fee_data.cur}}{{portfolio_cur}}</template>
                </el-input>
            </div>
            <div class="of-row-fees-col">
                <el-input v-model="portolio_cur_amount" v-if="show_prate">
                    <template slot="prepend">{{portfolio_cur}}</template>
                </el-input>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Urls from '@/../config/url.js'
import { num_to_money, money_to_num } from '@/utils'
export default {
    props: {
        fee: {
            type: Object,
            required: true,
        },
        accounts: {
            type: Array,
            required: true,
        },
        portfolio_cur: {
            type: String,
            required: true
        },
        trade_date: {
            type: Date,
            required: true
        },
    },

    data(){
        return {
            fee_data: this.fee,
            portolio_cur_amount: undefined,
        }
    },

    computed: {
        ...mapState({
            currency_list : state => state.currency.list,
        }),

        show_prate(){
            return (this.fee_data.cur === this.portfolio_cur) ? false : true
        },
        show_srate(){
            return false
        },
    },

    methods: {
        set_fee_prate(){
            if (this.fee_data.cur === this.portfolio_cur) {
                this.$set(this.fee_data, 'prate', 1)
            }
            else {
                let hist_date = this.$moment(this.trade_date).utcOffset('+0300').format('YYYY-MM-DD')
                let url = Urls.portfolioSearchInstrumentData
                return this.axios.get(url, {params:{
                    asset  : 'cash', 
                    ident  : this.fee.cur + this.portfolio_cur, 
                    fields : JSON.stringify(['price']), 
                    date   : hist_date
                }})
                .then(response => {
                    this.$set(this.fee_data, 'prate', response.data.price)
                })
                .catch(e => {
                    console.log(e)
                    this.$message({
                        message: "Can't get history value!",
                        type: 'error'
                    })
                });
            }
        },

        remove_fee(){
            this.$emit('remove-fee')
        },

        get_number_field(field){
            return (this.fee_data[field]) ? money_to_num(this.fee_data[field]) : 0;
        },
    },

    watch: {
        portfolio_cur(val) {
            this.set_fee_prate()
        },
        "fee.cur": function(val){
            this.set_fee_prate()
        },
        "fee.prate": function(val, old_val){
            if (val && (!old_val || old_val !== val)) {
                this.$set(this.fee_data, 'prate', num_to_money(val, 16));
            }

            if (old_val !== val){
                let am = this.get_number_field('am')
                let prate = this.get_number_field('prate')
                this.portolio_cur_amount = am * prate
            }
        },
        "fee.am": function(val, old_val){
            if (val && (!old_val || old_val !== val)) {
                this.$set(this.fee_data, 'am', num_to_money(val, 2));
            }

            if (old_val !== val){
                let am = this.get_number_field('am')
                let prate = this.get_number_field('prate')
                this.portolio_cur_amount = am * prate
            }
        },
        portolio_cur_amount(val, old_val) {
            if (val && (!old_val || old_val !== val)) {
                this.portolio_cur_amount = num_to_money(val, 2)
            }
        }
    },

    mounted(){
        if (this.fee.am)
            this.$set(this.fee_data, 'am', num_to_money(this.fee.am , 2));
        if (this.fee.prate)
            this.$set(this.fee_data, 'prate', num_to_money(this.fee.prate, 16));
        if (this.fee.am && this.fee.prate)
            this.portolio_cur_amount = this.fee.am * this.fee.prate;
    }
}
</script>
